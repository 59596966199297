.primary-border {
  border: 1px #008489 solid !important;
  border-radius: 4px !important; }

.secondary-border {
  border: 1px #FF5A5F solid !important;
  border-radius: 4px !important; }

.black-border {
  border: 1px #333333 solid !important;
  border-radius: 4px !important; }
