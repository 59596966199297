.cursor-pointer {
  cursor: pointer !important; }

.position-relative {
  position: relative !important; }

.hide-a-effect {
  color: inherit;
  text-decoration: none; }
  .hide-a-effect:focus, .hide-a-effect:hover, .hide-a-effect:visited, .hide-a-effect:link, .hide-a-effect:active {
    color: inherit;
    text-decoration: none; }
