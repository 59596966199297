.font-10 {
  font-size: 10px !important; }

.font-11 {
  font-size: 11px !important; }

.font-12 {
  font-size: 12px !important; }

.font-13 {
  font-size: 13px !important; }

.font-14 {
  font-size: 14px !important; }

.font-15 {
  font-size: 15px !important; }

.font-16 {
  font-size: 16px !important; }

.font-17 {
  font-size: 17px !important; }

.font-18 {
  font-size: 18px !important; }

.font-20 {
  font-size: 20px !important; }

.font-22 {
  font-size: 22px !important; }

.font-24 {
  font-size: 24px !important; }

.font-26 {
  font-size: 26px !important; }

.font-28 {
  font-size: 28px !important; }

.font-30 {
  font-size: 30px !important; }

.font-32 {
  font-size: 32px !important; }

.font-34 {
  font-size: 34px !important; }

.font-36 {
  font-size: 36px !important; }

.font-38 {
  font-size: 38px !important; }

.font-40 {
  font-size: 40px !important; }

.font-42 {
  font-size: 42px !important; }

.font-44 {
  font-size: 44px !important; }

.font-46 {
  font-size: 46px !important; }

.font-48 {
  font-size: 48px !important; }

.font-52 {
  font-size: 52px !important; }

.font-60 {
  font-size: 60px !important; }
