.primary-color {
  color: #008489 !important; }

.primary-dark--colopr {
  color: #006A70 !important; }

.secondary-color {
  color: #FF5A5F !important; }

.secondary-light--color {
  color: #E09EA0 !important; }

.secondary-dark--color {
  color: #FF5A5F !important; }

.white-color {
  color: #ffffff !important; }

.font-primary-color {
  color: #1f3349 !important; }

.font-title-color {
  color: #1f3349 !important; }

.link-color {
  color: #0069ff !important; }

.font-light-color {
  color: #666 !important; }
