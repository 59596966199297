.extra-thin-font {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 200 !important;
  font-style: normal !important; }

.thin-font {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300 !important;
  font-style: normal !important; }

.regular-font {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important; }

.semibold-font {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  font-style: normal !important; }

.bold-font {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 700 !important;
  font-style: normal; }

.title-font {
  font-family: 'Catamaran', sans-serif !important;
  font-weight: 800 !important;
  font-style: normal; }

.u-italic {
  font-style: italic; }

/**
 * Word breaking
 *
 * Break strings when their length exceeds the width of their container.
 */
.u-textBreak {
  word-wrap: break-word !important; }

/**
 * Horizontal text alignment
 */
.u-textCenter {
  text-align: center !important; }

.u-textLeft {
  text-align: left !important; }

.u-textRight {
  text-align: right !important; }

/**
 * Inherit the ancestor's text color.
 */
.u-textInheritColor {
  color: inherit !important; }

.u-letterSpacing25 {
  letter-spacing: 0.25px; }

.u-letterSpacing35 {
  letter-spacing: 0.35px; }

.u-letterSpacing60 {
  letter-spacing: 0.60px; }

/**
 * Enables font kerning in all browsers.
 * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
 *
 * 1. Chrome (not Windows), Firefox, IE 10+
 * 2. Safari 7 and future browsers
 * 3. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
 */
.u-textKern {
  font-feature-settings: "kern" 1;
  /* 1 */
  font-kerning: normal;
  /* 2 */
  text-rendering: optimizeLegibility;
  /* 3 */ }

/**
 * Prevent whitespace wrapping
 */
.u-textNoWrap {
  white-space: nowrap !important; }

/**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */
.u-textTruncate {
  max-width: 100%;
  /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  /* 2 */ }

html, body {
  width: 100%;
  min-width: 360px;
  height: 100%; }

body,
body p,
body .ui.header,
body .ui.button,
body .ui.input,
body .ui.input > input {
  line-height: 1.7;
  font-family: "Open Sans", Arial, sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  color: #1f3349;
  font-size: 16px;
  -webkit-font-smoothing: antialiased; }
