.ui.button.pes {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
  font-style: normal !important; }
.ui.button img.left-icon {
  width: 19px;
  height: 19px; }
.ui.button.switch-button {
  width: 150px; }
  .ui.button.switch-button.left {
    border-radius: 15px 0 0 15px !important; }
  .ui.button.switch-button.right {
    border-radius: 0 15px 15px 0 !important; }
