@import '../node_modules/semantic-ui-css/components/reset.min.css';
@import '../node_modules/semantic-ui-css/components/button.css';
@import '../node_modules/semantic-ui-css/components/input.min.css';
@import '../node_modules/semantic-ui-css/components/grid.min.css';
@import '../node_modules/semantic-ui-css/components/checkbox.min.css';
@import '../node_modules/semantic-ui-css/components/icon.min.css';
@import '../node_modules/semantic-ui-css/components/site.min.css';
@import '../node_modules/semantic-ui-css/components/dropdown.min.css';
@import '../node_modules/semantic-ui-css/components/transition.min.css';
@import '../node_modules/semantic-ui-css/components/label.min.css';
@import '../node_modules/semantic-ui-css/components/popup.min.css';
@import '../node_modules/semantic-ui-css/components/accordion.min.css';
@import '../node_modules/semantic-ui-css/components/list.min.css';
@import '../node_modules/semantic-ui-css/components/segment.min.css';
@import '../node_modules/semantic-ui-css/components/table.min.css';
@import '../node_modules/semantic-ui-css/components/divider.min.css';
@import '../node_modules/semantic-ui-css/components/modal.min.css';
@import '../node_modules/semantic-ui-css/components/placeholder.min.css';
@import '../node_modules/semantic-ui-css/components/statistic.min.css';
@import '../node_modules/semantic-ui-css/components/loader.min.css';
@import '../node_modules/semantic-ui-css/components/menu.min.css';
@import '../node_modules/semantic-ui-css/components/step.min.css';
@import '../node_modules/semantic-ui-css/components/header.min.css';
@import '../node_modules/semantic-ui-css/components/dimmer.min.css';
@import '../node_modules/semantic-ui-css/components/form.min.css';

@import 'common-style/device.css';
@import 'common-style/border.css';
@import 'common-style/text.css';
@import 'common-style/background.css';
@import 'common-style/margin-padding.css';
@import 'common-style/color.css';
@import 'common-style/size.css';
@import 'common-style/general.css';
@import 'common-style/flex.css';
@import 'common-style/dropbox.css';

/**
Override
 */
@import 'common-style/input.css';
@import 'common-style/button.css';

#law-ok {
  margin: 0 auto;
  text-align: center;
  min-height: 40px;
  background: #282828;
  width: 100vw;
  padding: 5px 0;
  color: white;
  position: fixed;
  z-index: 100000;
  bottom: 0px;
}

#law-ok .ui.button {
  line-height: 0.9 !important;
}




